:root {
  --loading-frame-light-color: #ffc100;
  --loading-frame-dark-color: #916f04;

  --grid-column-width: calc(100vw / 14);

  --border-width: 1rem;

  --sans: SansBlack, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;

  --mono: MonoRegular, "SFMono-Regular", Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
