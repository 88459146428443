#app-root {
  min-height: 100%;
  display: flex;
}

.frame {
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: var(--grid-column-width);
}

.frame-loading-state {
  background-color: var(--loading-frame-light-color);
  color: var(--loading-frame-light-color);
}

.frame-loading-state .frame-content {
  background-color: var(--loading-frame-dark-color);
  border-color: var(--loading-frame-dark-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame-loading-state .frame-content h1 {
  margin: 0;
}

.frame-content {
  position: relative;
  padding: var(--grid-column-width);
  border-width: var(--border-width);
  border-style: solid;
  width: 100%;
  height: auto;
  min-height: 100%;
}

.frame-title {
  font-family: var(--sans);
  font-size: 3.75rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 7px;
  margin-bottom: var(--grid-column-width);
  text-align: center;
}

.frame-number {
  font-family: var(--sans);
  font-size: 6.25rem;
  line-height: 1;
  position: absolute;
  top: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  padding-left: 1rem;

  margin-right: -0.06em;
  margin-top: -0.08em;
}

.frame-body {
  font-family: var(--mono);
  font-size: 0.8125rem;
  line-height: 1.46;
  max-width: 700px;
  margin: 0 auto;
}

.frame-body b {
  font-weight: 900;
}

.frame-body p {
  margin-bottom: 1rem;
}

.frame-body-text {
  width: 300px;
  margin-right: 70px;
  display: inline-block;
  vertical-align: top;
}

.frame-body-text-two-columns {
  column-count: 2;
  column-gap: 70px;
}

.frame-button {
  font-family: var(--sans);
  font-size: 3rem;
  background-color: inherit;
  border: none;
  padding: 0 1.5rem;
  height: 3rem;
  vertical-align: bottom;
  overflow: visible;
  position: absolute;
  bottom: calc(var(--border-width) * -1);
}

.frame-button-right {
  right: 0;
}

.frame-button-left {
  left: 0;
}

.frame-image {
  width: 318px;
  max-width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-top: calc(var(--grid-column-width) * 0.5 * -1);
}

@media only screen and (max-width: 1000px) {
  .frame-body {
    margin-bottom: 2rem;
  }
  .frame-body-text {
    margin-right: 0;
    width: 100%;
    margin: 0 auto;
    margin-bottom: var(--grid-column-width);
  }
  .frame-image {
    display: block;
    margin: 0 auto;
  }
  .frame-body-text-two-columns {
    columns: 1;
  }
  .frame-content {
    padding-top: calc(var(--grid-column-width) * 1.75);
    padding-bottom: calc(var(--grid-column-width) * 1.75);
  }
}

@media only screen and (max-width: 600px) {
  html,
  body {
    font-size: 12px;
  }

  .frame-title {
    letter-spacing: 2px;
    //font-size: 2.75rem;
  }

  .frame-content {
    padding-top: calc(var(--grid-column-width) * 2.5);
    padding-bottom: calc(var(--grid-column-width) * 2.5);
  }
}
